// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-past-events-js": () => import("./../src/templates/past-events.js" /* webpackChunkName: "component---src-templates-past-events-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-2020-index-js": () => import("./../src/pages/de/2020/index.js" /* webpackChunkName: "component---src-pages-de-2020-index-js" */),
  "component---src-pages-de-imprint-js": () => import("./../src/pages/de/imprint.js" /* webpackChunkName: "component---src-pages-de-imprint-js" */),
  "component---src-pages-de-index-js": () => import("./../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-privacy-policy-js": () => import("./../src/pages/de/privacy-policy.js" /* webpackChunkName: "component---src-pages-de-privacy-policy-js" */),
  "component---src-pages-en-gb-2020-index-js": () => import("./../src/pages/en-GB/2020/index.js" /* webpackChunkName: "component---src-pages-en-gb-2020-index-js" */),
  "component---src-pages-en-gb-imprint-js": () => import("./../src/pages/en-GB/imprint.js" /* webpackChunkName: "component---src-pages-en-gb-imprint-js" */),
  "component---src-pages-en-gb-index-js": () => import("./../src/pages/en-GB/index.js" /* webpackChunkName: "component---src-pages-en-gb-index-js" */),
  "component---src-pages-en-gb-privacy-policy-js": () => import("./../src/pages/en-GB/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-gb-privacy-policy-js" */),
  "component---src-pages-imprint-js": () => import("./../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

